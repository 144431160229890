import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import { BrandIntegration } from '@models/BrandIntegration';
import { Integration } from '@models/Integration';
import { responseIsSuccess } from '@services/api';
import * as IntegrationService from '@services/integration';

interface IntegrationState {
  integrations: Integration[];
  loadRequestIntegrations: Function;
  createIntegration: Function;
  updateIntegration: Function;
  errors: string[];
  loading: boolean;
  clearErrors: Function;
  setCurrentIntegrationById: Function;
  updateBrandsIntegrations: Function;
  clearCurrentIntegration: Function;
  currentIntegration: Integration | null;
}

interface IntegrationProviderProps {
  children: ReactNode;
}

const IntegrationContext = createContext<IntegrationState>(Object.assign({}));

const IntegrationProvider: React.FC<IntegrationProviderProps> = ({ children }) => {
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentIntegration, setCurrentIntegration] = useState<Integration | null>(null);

  const loadRequestIntegrations = async () => {
    try {
      setLoading(true);
      const { data: receivedIntegrations } = await IntegrationService.get();
      setIntegrations(receivedIntegrations);
    } catch (error) {
      setErrors(errors.concat('Erro ao carregar integrações'));
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const clearErrors = () => {
    setErrors([]);
  };

  const clearCurrentIntegration = () => {
    setCurrentIntegration(null);
  };

  const createIntegration = useCallback(
    async (integration: Integration, verifyCredentials: boolean = true): Promise<boolean> => {
      setLoading(true);
      try {
        const objIntegration = {
          ...integration,
          integrationUrl: integration?.integrationUrl?.trim(),
          integrationIsActive: true,
        };

        if (integration.integrationSystem === 2) {
          verifyCredentials = false;
        } // Se for integrado ao MERP

        if (verifyCredentials) {
          const { status: statusVerify, data: ok } = await IntegrationService.verifyCredentials(objIntegration);

          if (!responseIsSuccess(statusVerify)) {
            setErrors(['Ocorreu algum erro ao verificar as credenciais!']);
            return false;
          }
          if (!ok.status) {
            setErrors(['Credenciais incorretas! Favor verificar!']);
            return false;
          }
        }

        const { status, data: newIntegration } = await IntegrationService.save(objIntegration);
        if (!responseIsSuccess(status)) {
          setErrors([newIntegration?.response?.message ? newIntegration?.response?.message : newIntegration?.message]);
          return false;
        }
        if (integrations.length > 0) {
          setIntegrations([...integrations, newIntegration]);
        } else {
          setIntegrations([newIntegration]);
        }
        return true;
      } catch (error) {
        setErrors(['Erro ao criar integração']);
        console.error('error in context: ', error);
        return false;
      } finally {
        setLoading(false);
      }
    },
    [integrations],
  );

  const updateIntegration = useCallback(
    async (integration: Integration, verifyCredentials: boolean = true): Promise<boolean> => {
      setLoading(true);

      if (integration.integrationSystem === 2) {
        verifyCredentials = false;
      } // Se for integrado ao MERP

      try {
        const objIntegration = {
          ...integration,
          integrationUrl: integration?.integrationUrl?.trim(),
          integrationIsActive: integration?.integrationIsActive == undefined ? true : integration.integrationIsActive,
        };
        if (verifyCredentials) {
          const { status: statusIntegracao, data: ok } = await IntegrationService.verifyCredentials(objIntegration);
          if (!responseIsSuccess(statusIntegracao)) {
            setErrors(['Ocorreu algum erro ao verificar as credenciais!']);
            return false;
          }
          if (!ok.status) {
            setErrors(['Credenciais incorretas! Favor verificar!']);
            return false;
          }
        }
        const { status, data: updatedIntegration } = await IntegrationService.update(objIntegration);
        if (!responseIsSuccess(status)) {
          setErrors([updatedIntegration.message]);
          return false;
        }
        if (integrations.length != 0) {
          setIntegrations(
            integrations.map((element) => {
              if (element.integrationId === updatedIntegration.integrationId) {
                return updatedIntegration;
              }
              return element;
            }),
          );
        } else {
          setIntegrations([updatedIntegration]);
        }
        return true;
      } catch (error) {
        setErrors(['Erro ao alterar integração']);
        console.error(error);
        return false;
      } finally {
        setLoading(false);
      }
    },
    [integrations],
  );

  const updateBrandsIntegrations = async ({
    integrationId,
    brandsIntegration,
  }: {
    integrationId: number;
    brandsIntegration: BrandIntegration[];
  }): Promise<boolean> => {
    try {
      setLoading(true);
      const { status: status, data: updatedBrandsArray } = await IntegrationService.updateBrandsVinculation({
        integrationId,
        brandsIntegration,
      });
      if (!responseIsSuccess(status)) {
        setErrors([updatedBrandsArray.message]);
        return false;
      }
      const integration = integrations.find((element) => element.integrationId === integrationId);
      if (integration) {
        integration.brandsIntegrations = updatedBrandsArray;
        setIntegrations([...integrations, integration]);
        return true;
      }
      setErrors([...errors, 'Id não encontrado nas integrações carregadas!']);
      return false;
    } catch (error) {
      console.error('error in context: ', error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const setCurrentIntegrationById = useCallback(
    async (integrationId: number): Promise<boolean> => {
      try {
        setLoading(true);
        const { status: status, data: integration } = await IntegrationService.get(integrationId);
        if (!responseIsSuccess(status)) {
          setErrors([integration.message]);
          return false;
        }
        setCurrentIntegration(integration);
        return true;
      } catch (error) {
        console.error(error);
        setErrors([`Erro ao buscar integração ${integrationId}`]);
        return false;
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  return (
    <IntegrationContext.Provider
      value={{
        integrations,
        loading,
        errors,
        currentIntegration,
        clearCurrentIntegration,
        clearErrors,
        loadRequestIntegrations,
        createIntegration,
        updateIntegration,
        updateBrandsIntegrations,
        setCurrentIntegrationById,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};

const useIntegration = () => {
  return useContext(IntegrationContext);
};

export { IntegrationProvider, useIntegration };
