import React, { useCallback, useEffect, useState } from 'react';

import { Button, Checkbox, Col, Input, Modal, Popconfirm, Row, Switch, Table, Tabs, notification } from 'antd';

import { useManufacturer } from '@hooks/ManufacturerContext';
import { Manufacturer } from '@models/Manufacturer';
import ManufacturerGeneralInfo from './components/ManufacturerGeneralInfo';

import { useIntegration } from '@hooks/IntegrationContext';
import ManufacturerAddress from './components/ManufacturerAddress';
import ManufacturerBrands from './components/ManufacturerBrands';
import ManufacturerCompanies from './components/ManufacturerCompanies';
import ManufacturerIntegration from './components/ManufacturerIntegration';
import { IIntegrationForm } from './components/ManufacturerIntegration/models/IIntegrationForm';
import ManufacturerPermission from './components/ManufacturerPermission';
import './styles.css';

const ManufacturerPage: React.FC = () => {
  const {
    filteredManufacturers,
    loading,
    loadRequestManufacturer,
    filterManufacturers,
    loadManufacturersByIntegration,
    updateManufacturer,
    errors,
    clearErrors,
    currentManufacturer,
    setCurrentManufacturer,
  } = useManufacturer();
  const {
    integrations,
    loading: loadingIntegration,
    loadRequestIntegrations,
    createIntegration,
    updateIntegration,
    errors: errorsIntegration,
    clearErrors: clearErrorsIntegration,
    setCurrentIntegrationById,
    clearCurrentIntegration,
  } = useIntegration();
  const [filterManufacturerIsActive, setFilterManufactorerIsActive] = useState<boolean>();
  const [searchManufacturer, setSearchManufacturer] = useState<string>();
  const [activeManufacturerModalIsVisible, setActiveManufacturerModalIsVisible] = useState<boolean>(false);
  const [manufacturerDetailsModalIsVisible, setManufacturerDetailsModalIsVisible] = useState<boolean>(false);
  const [disabledTabPanes, setDisabledTabPanes] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>('1');
  const { TabPane } = Tabs;

  const handleToggleManufacturers = useCallback(
    async (manufacturer: Manufacturer) => {
      const newActive = !manufacturer.integrationId.integrationIsActive;
      const success: boolean = await updateIntegration(
        {
          ...manufacturer.integrationId,
          integrationIsActive: newActive,
        },
        false,
      );
      if (errorsIntegration?.length < 1 && success) {
        manufacturer.integrationId.integrationIsActive = newActive;
        notification.success({
          message: 'Sucesso',
          description: `Integração do fabricante ${manufacturer.manufacturerId} 
              ${newActive ? 'ativada' : 'desativada'} com sucesso`,
        });
      }
    },
    [updateManufacturer],
  );

  const handleSaveIntegration = useCallback(
    async (integration: IIntegrationForm): Promise<boolean> => {
      let success = null;
      let messageNotification: 'cadastrada' | 'alterada';
      if (!integration.integrationId) {
        success = await createIntegration(integration);
        messageNotification = 'cadastrada';
      } else {
        success = await updateIntegration(integration);
        messageNotification = 'alterada';
      }
      if (errorsIntegration?.length < 1 && success) {
        notification.success({ message: 'Sucesso', description: `Integração ${messageNotification} com sucesso` });
        setManufacturerDetailsModalIsVisible(false);
        loadRequestManufacturer(true);
        return true;
      }
      return false;
    },
    [createIntegration, updateIntegration],
  );

  useEffect(() => {
    loadRequestIntegrations();
  }, []);

  useEffect(() => {
    loadRequestManufacturer(true);
  }, [integrations]);

  useEffect(() => {
    clearCurrentIntegration();
    if (currentManufacturer && currentManufacturer.integrationId.integrationId) {
      loadManufacturersByIntegration(currentManufacturer.integrationId.integrationId);
      if (integrations) {
        setCurrentIntegrationById(currentManufacturer.integrationId.integrationId);
      }
    }
  }, [currentManufacturer]);

  useEffect(() => {
    if (errors) {
      errors.forEach((errorMessage: string) => {
        notification.error({
          message: 'Erro',
          description: errorMessage,
          onClose: () => {
            clearErrors();
          },
        });
      });
    }
  }, [errors]);

  useEffect(() => {
    if (errorsIntegration) {
      errorsIntegration?.forEach((errorMessage: string) => {
        notification.error({
          message: 'Erro',
          description: errorMessage,
          onClose: () => {
            clearErrorsIntegration();
          },
        });
      });
    }
  }, [errorsIntegration]);

  useEffect(() => {
    if (searchManufacturer !== undefined || filterManufacturerIsActive !== undefined) {
      filterManufacturers(searchManufacturer, filterManufacturerIsActive);
    }
  }, [searchManufacturer, filterManufacturerIsActive]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'manufacturerId',
      sorter: (manufacturer: Manufacturer, nextManufacturer: Manufacturer) =>
        manufacturer.manufacturerId - nextManufacturer.manufacturerId,
    },
    {
      title: 'Razão Social',
      dataIndex: 'manufacturerName',
      sorter: (manufacturer: Manufacturer, nextManufacturer: Manufacturer) =>
        manufacturer.manufacturerFantasyName.localeCompare(nextManufacturer.manufacturerFantasyName),
    },
    {
      title: 'Nome Fantasia',
      dataIndex: 'manufacturerFantasyName',
      sorter: (manufacturer: Manufacturer, nextManufacturer: Manufacturer) =>
        manufacturer.manufacturerFantasyName.localeCompare(nextManufacturer.manufacturerFantasyName),
    },
    {
      title: 'CNPJ',
      dataIndex: 'manufacturerCnpj',
      render: (value: number) => {
        return value?.toString().replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      },
    },
    {
      title: 'Cód. Emp.',
      dataIndex: 'manufacturerExternalId',
      sorter: (manufacturer: Manufacturer, nextManufacturer: Manufacturer) =>
        manufacturer.manufacturerExternalId > nextManufacturer.manufacturerExternalId ? 1 : -1,
    },
    {
      title: 'Ações',
      dataIndex: '',
      render: (value: any, manufacturer: Manufacturer): JSX.Element => {
        return (
          <>
            <a
              onClick={() => {
                setCurrentManufacturer(manufacturer);
                setDisabledTabPanes([]);
                setManufacturerDetailsModalIsVisible(true);
              }}
            >
              Ver Detalhes
            </a>
            <Popconfirm
              title={`Tem certeza que deseja ${
                manufacturer.integrationId.integrationIsActive ? 'desativar' : 'ativar'
              } a integração do fabricante ${manufacturer.manufacturerId}?`}
              onConfirm={() => handleToggleManufacturers(manufacturer)}
              okText="Sim"
              cancelText="Não"
            >
              <Switch checked={manufacturer.integrationId.integrationIsActive} className="ml-4" />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row className="my-8" align="middle">
        <Col span="16" className="d-flex justify-space-between">
          <Input.Search
            onSearch={(search) => setSearchManufacturer(search)}
            placeholder="Busca por CNPJ, nome ou nome fantasia"
            size="large"
            allowClear
          />
        </Col>
        <Col>
          <Checkbox
            className="ml-4"
            onChange={(checkBoxChangeEvent) => setFilterManufactorerIsActive(checkBoxChangeEvent.target.checked)}
          >
            Filtrar apenas ativos
          </Checkbox>
        </Col>
        <Col>
          <Button
            size="large"
            type="primary"
            className="ml-4 my-1"
            onClick={() => {
              setCurrentManufacturer(null);
              setDisabledTabPanes(['2', '3', '4', '5', '6']);
              setActiveTab('1');
              setManufacturerDetailsModalIsVisible(true);
            }}
          >
            Novo fabricante
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        loading={loading || loadingIntegration}
        dataSource={filteredManufacturers}
        rowKey={(element) => element.manufacturerId}
      />
      <Modal
        title={null}
        visible={activeManufacturerModalIsVisible}
        onOk={() => setActiveManufacturerModalIsVisible(false)}
        onCancel={() => setActiveManufacturerModalIsVisible(false)}
      >
        <p>
          Deseja Ativar ou desativar o <strong>Fabricante?</strong>
        </p>
      </Modal>

      <Modal
        title="Detalhes do fabricante"
        className="pt-4"
        width={768}
        visible={manufacturerDetailsModalIsVisible}
        footer={null}
        onCancel={() => setManufacturerDetailsModalIsVisible(false)}
      >
        <Tabs
          defaultActiveKey="1"
          activeKey={activeTab}
          onTabClick={(activeKey) => {
            setActiveTab(activeKey);
          }}
        >
          <TabPane tab="Integrações" key="1" disabled={disabledTabPanes.includes('1')}>
            <ManufacturerIntegration onSubmit={handleSaveIntegration} />
          </TabPane>
          <TabPane tab="Empresas" key="2" disabled={disabledTabPanes.includes('2')}>
            <ManufacturerCompanies />
          </TabPane>
          <TabPane tab="Dados Gerais" key="3" disabled={disabledTabPanes.includes('3')}>
            <ManufacturerGeneralInfo />
          </TabPane>
          <TabPane tab="Endereço" key="4" disabled={disabledTabPanes.includes('4')}>
            <ManufacturerAddress />
          </TabPane>
          <TabPane tab="Marcas" key="5" disabled={disabledTabPanes.includes('5')}>
            <ManufacturerBrands />
          </TabPane>
          <TabPane tab="Permissões" key="6" disabled={disabledTabPanes.includes('6')}>
            <ManufacturerPermission />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default ManufacturerPage;
