import React, { createContext, useState, ReactNode, useContext, useCallback, useEffect } from 'react';

import { Manufacturer } from '@models/Manufacturer';
import * as ManufacturerService from '@services/manufacturer';
import { responseIsSuccess } from '@services/api';
import { Address } from '@models/Address';

interface ManufacturerState {
  loading: boolean;
  filteredManufacturers: Manufacturer[];
  manufacturers: Manufacturer[];
  loadRequestManufacturer: Function;
  manufacturersByIntegration: Manufacturer[];
  loadManufacturersByIntegration: Function;
  createManufacturer: Function;
  updateManufacturer: Function;
  errors: string[];
  clearErrors: Function;
  filterManufacturers: Function;
  currentManufacturer: Manufacturer | null;
  setCurrentManufacturer: Function;
  // currentHeadOffice: Manufacturer | null;
  // setCurrentHeadOffice: Function;
  clearManufacturers: Function;
  getAddressByManufacturerId: Function;
  setManufacturersByIntegration: Function;
}

interface ManufacturerProviderProps {
  children: ReactNode;
}

const ManufacturerContext = createContext<ManufacturerState>(Object.assign({}));

const ManufacturerProvider: React.FC<ManufacturerProviderProps> = ({ children }) => {
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [filteredManufacturers, setFilteredManufacturers] = useState<Manufacturer[]>([]);
  const [manufacturersByIntegration, setManufacturersByIntegration] = useState<Manufacturer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [currentManufacturer, setCurrentManufacturer] = useState<Manufacturer | null>(null);
  // const [currentHeadOffice, setCurrentHeadOffice] = useState<Manufacturer | null>(null);

  useEffect(() => {
    setFilteredManufacturers(manufacturers);
  }, [manufacturers]);

  const clearErrors = () => {
    setErrors([]);
  };

  const clearManufacturers = () => {
    setManufacturers([]);
  };

  //Pesquisa também sem os espaços e coloca numa ordem de maior relevância
  const filterManufacturers = (searchedText: string, onlyActive = false) => {
    const notSpaceAndSpecialCharacters = /[^\w]/gi;
    const treatedSearchedText = searchedText
      ? searchedText?.trim().toLocaleLowerCase().replace(notSpaceAndSpecialCharacters, '')
      : '';

    let foundManufacturers = manufacturers.filter((manufacturer) => {
      if (
        manufacturer.manufacturerCnpj
          ?.toString()
          .replace(notSpaceAndSpecialCharacters, '')
          .includes(treatedSearchedText) ||
        manufacturer.manufacturerFantasyName
          ?.replace(notSpaceAndSpecialCharacters, '')
          .toLocaleLowerCase()
          .includes(treatedSearchedText) ||
        manufacturer.manufacturerName
          ?.replace(notSpaceAndSpecialCharacters, '')
          .toLocaleLowerCase()
          .includes(treatedSearchedText)
      ) {
        return manufacturer;
      }
    });
    if (onlyActive) {
      foundManufacturers = foundManufacturers.filter(
        (manufacturer) => manufacturer?.integrationId?.integrationIsActive,
      );
    }
    setFilteredManufacturers(foundManufacturers);
  };

  const loadRequestManufacturer = useCallback(
    async (onlyHeadOffices = false) => {
      setLoading(true);
      try {
        const { data: manufacturersHeadOffices } = await ManufacturerService.get(onlyHeadOffices);
        setManufacturers(manufacturersHeadOffices);
      } catch (error) {
        setErrors(['Erro ao carregar fabricantes!']);
        console.error('error in context', error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setManufacturers, setErrors],
  );

  const loadManufacturersByIntegration = useCallback(
    async (integrationId: number) => {
      setLoading(true);
      try {
        const { status, data: receivedManufacturers } = await ManufacturerService.getByIntegration(integrationId);
        if (!responseIsSuccess(status)) {
          setErrors(receivedManufacturers.message);
        }
        setManufacturersByIntegration(
          receivedManufacturers.sort((a: Manufacturer, b: Manufacturer) => {
            return a.manufacturerId > b.manufacturerId ? 1 : -1;
          }),
        );
      } catch (error) {
        setErrors(['Erro ao carregar fabricantes por integração!']);
        console.error('error in context', error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setErrors, setManufacturersByIntegration, manufacturers],
  );

  const getAddressByManufacturerId = async (manufacturerId: number): Promise<Address | null> => {
    try {
      setLoading(true);
      const { status: status, data: address } = await ManufacturerService.getAddressByManufacturerId(manufacturerId);
      if (!responseIsSuccess(status)) {
        setErrors([address.message]);
        return null;
      } else {
        return address;
      }
    } catch (error) {
      console.error(error);
      setErrors(['Erro ao carregar fabricantes por integração!']);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const createManufacturer = useCallback(
    async (manufacturer: Manufacturer): Promise<Manufacturer | null> => {
      try {
        setLoading(true);
        const { status, data: newManufacturer } = await ManufacturerService.save({
          ...manufacturer,
          integrationId: manufacturer.integrationId.integrationId,
        });
        if (!responseIsSuccess(status)) {
          setErrors(newManufacturer.message);
          return null;
        }

        if (manufacturers == null || manufacturers.length === 0) {
          setManufacturers([newManufacturer]);
        } else {
          setManufacturers(manufacturers.concat(newManufacturer));
        }
        return newManufacturer;
      } catch (error) {
        console.error('error in context', error);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [manufacturers],
  );

  const updateManufacturer = useCallback(
    async (manufacturer: Manufacturer): Promise<Manufacturer | null> => {
      try {
        setLoading(true);
        const { status, data: updatedManufacturer } = await ManufacturerService.update({
          ...manufacturer,
          integrationId: manufacturer.integrationId.integrationId,
        });
        if (!responseIsSuccess(status)) {
          console.warn(updatedManufacturer.message);
          throw new Error('Erro ao alterar fabricante');
        } else {
          if (updatedManufacturer) {
            setManufacturers(
              manufacturers
                .map((element) => {
                  if (element.manufacturerId === updatedManufacturer.manufacturerId) {
                    return updatedManufacturer;
                  }
                  return element;
                })
                .sort((a: Manufacturer, b: Manufacturer) => {
                  return a.manufacturerId > b.manufacturerId ? 1 : -1;
                }),
            );
          } else {
            setManufacturers([updatedManufacturer]);
          }
          return updatedManufacturer;
        }
      } catch (error) {
        console.error('error no context', error);
        setErrors([...errors, 'Erro ao alterar fabricante!']);
        return null;
      } finally {
        setLoading(false);
      }
    },
    [manufacturers],
  );

  return (
    <ManufacturerContext.Provider
      value={{
        manufacturers,
        filteredManufacturers,
        manufacturersByIntegration,
        loading,
        errors,
        currentManufacturer,
        // currentHeadOffice,
        getAddressByManufacturerId,
        clearErrors,
        clearManufacturers,
        createManufacturer,
        loadRequestManufacturer,
        loadManufacturersByIntegration,
        updateManufacturer,
        filterManufacturers,
        setCurrentManufacturer,
        setManufacturersByIntegration,
        // setCurrentHeadOffice,
      }}
    >
      {children}
    </ManufacturerContext.Provider>
  );
};

const useManufacturer = () => {
  const context = useContext(ManufacturerContext);
  return context;
};

export { ManufacturerProvider, useManufacturer };
